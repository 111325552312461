import React from "react";

function HomepageSectionHeader({ headerContent, linerContent }) {
  return (
    <div className="section-header">
      <h2 className="section-header__top">{headerContent}</h2>
      <h3 className="section-header__bottom">{linerContent}</h3>
    </div>
  );
}

export default HomepageSectionHeader;
