// ! Checkout related actions
const cartDrawerOpen = () => {
  return {
    type: "CART_DRAWER_OPEN",
  };
};
const cartTooltipOpen = (data) => {
  return {
    type: "CART_TOOLTIP_OPEN",
    payload: data,
  };
};
const cartTooltipClose = () => {
  return {
    type: "CART_TOOLTIP_CLOSE",
  };
};
const addToCart = (data) => {
  return {
    type: "ADD_TO_CART",
    payload: data,
  };
};
const addQtyToCart = (data) => {
  return {
    type: "ADD_QTY_TO_CART",
    payload: data,
  };
};
const removeQtyFromCart = (data) => {
  return {
    type: "REMOVE_QTY_FROM_CART",
    payload: data,
  };
};
const paymentSuccessful = () => {
  return {
    type: "PAYMENT_SUCCESSFUL",
  };
};
const setPromoCode = (data) => {
  return {
    type: "SET_PROMO_CODE",
    payload: data,
  };
};
const setPromoCodeResponse = (data) => {
  return {
    type: "SET_PROMO_CODE_RESPONSE",
    payload: data,
  };
};
const setPromoCodeUiStates = (data) => {
  return {
    type: "SET_PROMO_CODE_UI_STATES",
    payload: data,
  };
};
const setAmountPaid = (data) => {
  return { type: "SET_AMOUNT_PAID", payload: data };
};

const setPrediscountPrice = (data) => {
  return { type: "SET_PRE_DISCOUNT_AMOUNT", payload: data };
};

const setBuynowCourseId = (data) => {
  return { type: "SET_BUY_NOW_COURSE_ID", payload: data };
};

const setAddress = (data) => {
  return { type: "SET_ADDRESS", payload: data };
};
const setDirectBuyDetails = (data) => {
  return { type: "SET_DIRECT_BUY_DETAILS", payload: data };
};
const setBatchSelectionModalOpen = () => {
  return { type: "SET_BATCH_SELECTION_MODAL_OPEN" };
};
const setSelectedBatchId = (data) => {
  return { type: "SET_SELECTED_BATCH_ID", payload: data };
};
export {
  cartDrawerOpen,
  cartTooltipOpen,
  cartTooltipClose,
  addToCart,
  addQtyToCart,
  removeQtyFromCart,
  paymentSuccessful,
  setPromoCode,
  setPromoCodeResponse,
  setPromoCodeUiStates,
  setAmountPaid,
  setAddress,
  setDirectBuyDetails,
  setBatchSelectionModalOpen,
  setSelectedBatchId,
  setBuynowCourseId,
  setPrediscountPrice,
};
