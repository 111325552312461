import React, { useState, useEffect } from "react";
import HomepageSectionHeader from "../headers/HomepageSectionHeader";
import testimonialData from "../../store/staticData/testimonialDataV2.json";
// ! Swiper
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import TestimonialQuotes from "../../public/assets/customAssets/TestimonialQuotes";
SwiperCore.use([Autoplay, Pagination]);
// ! Single testimonial slide
const SingleSlide = ({ darkColor, lightColor, content, source }) => {
  return (
    <div
      className="spark-testimonial__single-slide"
      style={{ backgroundColor: lightColor }}
    >
      <TestimonialQuotes
        color={darkColor}
        className="spark-testimonial__single-slide--quotes"
      />
      <p className="spark-testimonial__single-slide--content">
        {content.split("|").map((line) => {
          return (
            <>
              {line} <br />
            </>
          );
        })}
      </p>
      <p className="spark-testimonial__single-slide--source">{source}</p>
    </div>
  );
};
function Testimonial({ vertical, filterOn }) {
  // ! State for responsive mode
  const [responsiveMode, setResponsiveMode] = useState(false);
  const [filteredTestimonials, setFilteredTestimonials] =
    useState(testimonialData);
  useEffect(() => {
    if (window.innerWidth < 545) {
      setResponsiveMode(true);
    }
    if (vertical)
      setFilteredTestimonials(
        testimonialData.filter(
          (item) => item.testimonial_course.toLowerCase() === vertical
        )
      );
    else if (filterOn) {
      let localFilteredTestimonials = [];
      filterOn.forEach((item) => {
        let testimonials = testimonialData.filter(
          (data) => data.testimonial_course.toLowerCase() === item.toLowerCase()
        );
        localFilteredTestimonials = [
          ...localFilteredTestimonials,
          ...testimonials,
        ];
      });
      setFilteredTestimonials(localFilteredTestimonials);
    }
  }, []);
  return (
    <div className="spark-testimonial__wrapper">
      <HomepageSectionHeader
        headerContent="10,000+ Parents Love Spark Studio"
        linerContent="Hear about their Spark Studio experience"
      />
      <div className="spark-testimonial">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={0}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          centeredSlides={true}
          className="spark-testimonial__swiper"
          autoplay={{ delay: 5000 }}
        >
          {filteredTestimonials.length &&
            filteredTestimonials.map((testimonial, index) => {
              return (
                <SwiperSlide>
                  <SingleSlide
                    source={testimonial.testimonial_source}
                    content={testimonial.testimonial_content}
                    darkColor={
                      testimonial.testimonial_course_category_color_dark
                    }
                    lightColor={
                      testimonial.testimonial_course_category_color_light
                    }
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
}

export default Testimonial;
