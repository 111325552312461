import React from "react";

function TestimonialQuotes({ color, className }) {
  return (
    <svg
      width="108"
      height="92"
      viewBox="0 0 108 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.4486 91.9969C18.7269 91.9896 12.2825 89.3266 7.52955 84.5924C2.77655 79.8582 0.103077 73.4393 0.0956991 66.744C0.0956991 66.7024 0.0956991 66.6592 0.0956991 66.6176C0.0956991 66.5343 0.0848598 66.4093 0.0755691 66.2443C-0.444713 57.0735 1.06039 32.3126 26.212 0.584929C26.4385 0.299976 26.7588 0.103856 27.1163 0.0311642C27.4738 -0.0415273 27.8457 0.0138806 28.1662 0.187613C28.4867 0.361346 28.7353 0.642242 28.8682 0.98075C29.001 1.31926 29.0096 1.69363 28.8924 2.03782C28.8212 2.24758 21.9476 22.7562 26.644 41.522C33.259 41.8312 39.4899 44.7061 44.0044 49.5319C48.5188 54.3578 50.9584 60.7515 50.8014 67.3457C50.6444 73.9399 47.9034 80.2113 43.1644 84.8186C38.4254 89.4259 32.0647 92.0034 25.4424 92L25.4486 91.9969ZM3.19262 66.8319C3.20975 71.2127 4.52959 75.4901 6.98551 79.1243C9.44143 82.7585 12.9233 85.5864 16.9915 87.251C21.0598 88.9156 25.5319 89.3423 29.8435 88.4772C34.155 87.6121 38.1125 85.494 41.2164 82.3903C44.3203 79.2867 46.4314 75.3365 47.2831 71.0387C48.1349 66.7409 47.6891 62.288 46.0021 58.2423C44.315 54.1967 41.4624 50.7395 37.8043 48.3075C34.1463 45.8754 29.8467 44.5774 25.4486 44.5774C25.1095 44.5773 24.7798 44.4663 24.5102 44.2615C24.2405 44.0567 24.0458 43.7694 23.9559 43.4437C20.3944 30.5343 22.1411 16.9924 23.911 8.77481C3.96065 36.6111 2.70795 57.8585 3.17404 66.0716C3.19507 66.3244 3.20127 66.5783 3.19262 66.8319Z"
        fill={color}
        fillOpacity="0.5"
      />
      <path
        d="M82.4389 91.9969C75.7171 91.9896 69.2728 89.3266 64.5198 84.5924C59.7668 79.8582 57.0933 73.4393 57.0859 66.744C57.0859 66.7024 57.0859 66.6592 57.0859 66.6176C57.0859 66.5343 57.0751 66.4093 57.0658 66.2443C56.5455 57.0735 58.0506 32.3126 83.2022 0.584929C83.4288 0.299976 83.749 0.103856 84.1065 0.0311642C84.4641 -0.0415273 84.8359 0.0138806 85.1564 0.187613C85.4769 0.361346 85.7255 0.642242 85.8584 0.98075C85.9913 1.31926 85.9999 1.69363 85.8826 2.03782C85.8114 2.24758 78.9378 22.7562 83.6343 41.522C90.2492 41.8312 96.4801 44.7061 100.995 49.5319C105.509 54.3578 107.949 60.7515 107.792 67.3457C107.635 73.9399 104.894 80.2113 100.155 84.8186C95.4156 89.4259 89.0549 92.0034 82.4327 92L82.4389 91.9969ZM60.1829 66.8319C60.2 71.2127 61.5198 75.4901 63.9757 79.1243C66.4317 82.7585 69.9135 85.5864 73.9818 87.251C78.05 88.9156 82.5222 89.3423 86.8337 88.4772C91.1452 87.6121 95.1027 85.494 98.2066 82.3903C101.311 79.2867 103.422 75.3365 104.273 71.0387C105.125 66.7409 104.679 62.288 102.992 58.2423C101.305 54.1967 98.4526 50.7395 94.7946 48.3075C91.1365 45.8754 86.837 44.5774 82.4389 44.5774C82.0997 44.5773 81.77 44.4663 81.5004 44.2615C81.2307 44.0567 81.036 43.7694 80.9461 43.4437C77.3847 30.5343 79.1313 16.9924 80.9012 8.77481C60.9509 36.6111 59.6966 57.8585 60.1627 66.0716C60.1843 66.3244 60.191 66.5783 60.1829 66.8319Z"
        fill={color}
        fillOpacity="0.5"
      />
    </svg>
  );
}

export default TestimonialQuotes;
